<template>
    <div>
        <Table :columns="realColumns" :data="data" border>
            <template slot="productPic" slot-scope="{ row }">
                <ViewImg
                    v-if="row.needItemList[0] && row.needItemList[0].productPic"
                    :src="row.needItemList[0].productPic"
                />
            </template>
            <template slot="receiverTerminalAddress" slot-scope="{ row }">
                <Tooltip :content="row.receiverTerminalAddress" placement="left" class="wp100">
                    <div class="ellipse">
                        {{ row.receiverTerminalAddress }}
                    </div>
                </Tooltip>

            </template>
            <template slot="action" slot-scope="{ row }">
                <!-- 之前抢单  展示为查看 跳转到查看 -->
                <Button
                    v-if="!bidViewDisable(row)"
                    class="mr10"
                    type="text"
                    @click="handleBid(row)"
                >
                    查看
                </Button>
                <Button
                    v-if="bidViewDisable(row)"
                    class="mr10"
                    type="text"
                    @click="handleView(row)"
                >
                    查看
                </Button>
            </template>
        </Table>
        <div class="flex-end">
            <Page
                :total="pageOpts.total"
                :page-size="pageOpts.size"
                :current="pageOpts.current"
                show-sizer
                show-elevator
                class="mt10"
                @on-change="pageChange"
                @on-page-size-change="pageSizeChange"
            />
        </div>
    </div>
</template>
<script>
import ViewImg from '@/components/ViewImg.vue';

export default {
    components: { ViewImg },
    props: {
        tabType: {},
        formQuery: {},
    },
    data() {
        return {
            columns: [
                // {
                //   title: '需求单号',
                //   key: 'needSn',
                //   width: 200,
                //   resizable: true,
                //   className: 'min-width'
                // },
                {
                    title: '发布日期',
                    key: 'publishTime',
                    width: 120,
                    render(h, { row }) {
                        return h(
                            'span',
                            dayjs(_.get(row, 'publishTime')).format(
                                'YYYY-MM-DD',
                            ),
                        );
                    },
                },

                {
                    title: '买家类型',
                    key: 'type',
                    width: 100,
                    render(h, { row }) {
                        return h('span', _.get(row, 'orgQualification'));
                    },
                },
                {
                    title: '买家区域',
                    width: 140,
                    // key: 'region'
                    // key: 'receiverDetailAddress',
                    // slot: 'receiverDetailAddress',
                    key: 'receiverTerminalAddress',
                    slot: 'receiverTerminalAddress',
                    ellipsis: true,
                },

                {
                    title: '品牌',
                    key: 'brand',
                    width: 160,
                    render: (h, { row }) => {
                        let text = _.get(row, 'needItemList.0.productBrand');
                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },

                {
                    title: '货号',
                    key: 'productNo',
                    width: 160,
                    ellipsis: true,
                    render: (h, { row }) => {
                        let text = _.get(row, 'needItemList.0.productNo');
                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: '名称',
                    key: 'productName',
                    width: 160,
                    render: (h, { row }) => {
                        let text = _.get(row, 'needItemList.0.productName');
                        if (!text) {
                            return (
                                <span>{this.$emptyContent}</span>
                            );
                        }
                        return (
                            <Tooltip placement="left" content={text} class="wp100">
                                <div class="ellipse">{text}</div>
                            </Tooltip>
                        );
                    },
                },
                {
                    title: '图片',
                    width: 70,
                    resizable: true,
                    tooltip: true,
                    key: 'productPic',
                    slot: 'productPic',
                },
                {
                    title: '数量',
                    key: 'productQuantity',
                    width: 80,
                    render(h, { row }) {
                        let text = _.get(row, 'needItemList.0.productQuantity');
                        let unit = _.get(row, 'needItemList.0.unit');
                        if (unit) {
                            text += unit;
                        }
                        return h('span', text);
                    },
                },

                {
                    title: '状态',
                    key: 'status',
                    width: 100,
                    render(h, { row }) {
                        if (row.intentStatus) {
                            return h(
                                'span',
                                _.get(
                                    $const.needIntentStatus[
                                        _.get(row, 'intentStatus')
                                    ],
                                    'display',
                                ),
                            );
                        }
                        return h(
                            'span',
                            _.get(
                                $const.needStatus[_.get(row, 'status')],
                                'display',
                            ),
                        );
                    },
                },

                // {
                //   title: '收货地址',
                //   width: 120,
                //   resizable: true,
                //   tooltip: true,
                //   key: 'receiverDetailAddress',
                //   // sortable: true
                // },
                // {
                //   title: '下单人',
                //   width: 120,
                //   resizable: true,
                //   key: 'memberUsername'
                // },
                {
                    title: '操作',
                    key: 'action',
                    slot: 'action',
                    minWidth: 80,
                    // width: 80,
                },
            ],
            data: [],
            pageOpts: {
                total: 0,
                size: 10,
                current: 1,
            },
        };
    },
    computed: {
        realColumns() {
            let t = this.tabType || '1';
            if (t - 1 !== 0) {
                return this.columns.filter((it) => it.key !== 'status');
            }
            return this.columns;
        },
    },
    watch: {
        tabType: {
            handler() {
                this.getData();
            },
            immediate: true,
        },
    },
    methods: {
        bidViewDisable(row) {
            return +row.status !== 2 || +row.intentStatus > 0;
        },
        async getData(silent = false) {
            window.registerUpdateRunner = () => this.getData(); // 仅此刷新

            let formData = _.cloneDeep(this.formQuery || { daterange: [] });
            let [start, end] = formData.daterange || [];

            formData.startPublishTime = '';
            formData.endPublishTime = '';

            if (start) {
                formData.startPublishTime = dayjs(start).format(
                    'YYYY-MM-DD HH:mm:ss',
                );
                formData.endPublishTime = dayjs(end).format(
                    'YYYY-MM-DD HH:mm:ss',
                );
            }
            formData = _.omit(formData, 'daterange');

            let params = {
                pageSize: this.pageOpts.size,
                currentPage: this.pageOpts.current,
                ...formData,
                tab: 1,
            };
            if (+this.tabType) {
                params.tab = this.tabType;
            }
            let res = await $service.needIntent.pageBySupplier(params, {silent});

            let data = _.get(res, 'data') || {};

            let total = _.get(data, 'total') || 0;
            // let current = _.get(data, 'current') || 0;
            // let size = _.get(data, 'size') || 0;
            let records = _.get(data, 'records') || [];
            // this.pageOpts = {total, size, current};
            this.pageOpts.total = total;
            this.data = records;
        },
        pageChange(current) {
            this.pageOpts.current = current;
            this.getData();
        },
        pageSizeChange(size) {
            this.pageOpts.size = size;
            this.getData();
        },
        // 操作
        handleView(row) {
            this.$router.push({
                path: '/home/seller/view',
                query: {
                    id: row.id,
                },
            });
        },
        handleBid(row) {
            this.$router.push({
                path: '/home/seller/bid',
                query: {
                    id: row.id,
                },
            });
        },
    },
};
</script>
